import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SolutionLayout } from "../components/solutions-layout"

export default function HRManagement() {
  return (
    <Layout tcoreinsight={false}>
      <SEO title="HR Management" />
      <SolutionLayout
        data={{
          slug: "core-people",
          hero: {
            image: require("./../assets/Image 31.png"),
            titleFirst: "",
            titleSecond: "CorePeople",
            subtitle:
              "Cloud based HR management system tailor-made for your people.",
            content:
              "HR involves numerous complex functions and processing.With CorePeople, you get a simple yet comprehensive and flexible system to manage all your HR processes so you can focus more on your people.",
          },
          section: {
            title: "How can CorePeople help you?",
            subtitle: (
              <>
                CorePeople automates all your HR processes in simple steps to
                offer a seamless experience for your team.
                <br /> From hiring to onboarding down to attendance and
                payrolls.
              </>
            ),
          },
          imageSection1: {
            image: require("./../assets/Image 33.png"),
            title: "Manage Data Efficiently",
            content:
              "Manage both your data collection and secure storage with our employee database management system.Set up your organization and easily maintain all your employee records in one central location.",
          },
          imageSection2: {
            image: require("./../assets/Image 32.png"),
            title: "Easy Staff Management",
            content:
              "CorePeople comes with attendance and leave tracker.It keeps track of time worked and handles all shift schedules.",
          },
          information: [
            {
              title: "Ticket Management",
              content:
                "Most HRs need a smoother mechanism through which all employees can contact HR.With our HRM system employees can initiate tickets, and HR can sort, track and resolve issues on time, all the time.",
            },
            {
              title: "Quickly send out information",
              content:
                "Whether sending out team tasks or updating your policies, there’s never been a better way to manage and share information.",
            },
            {
              title: "Manage all staff expenses",
              content:
                "Our HRM system provide tools to track and manage all company expenses easily.",
            },
            {
              title: "Easy Management of payroll",
              content:
                "Our HRM system help you to manage the computation, disbursement, and reporting of employees’ salaries efficiently and accurately.",
            },
            {
              title: "Advanced Analytics",
              content:
                "You can’t improve what you don’t measure. Gather meaningful insights about your workforce so you can identify bottlenecks and make informed decisions.",
            },
            {
              title: "Seamless Integrations",
              content:
                "Our HRM integrates cleanly with your email and third- party applications.So why shift between multiple apps when your systems can do the talking?",
            },
          ],
        }}
      />
    </Layout>
  )
}
